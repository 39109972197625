var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('PilotCard',{ref:"preview",attrs:{"title":_vm.title,"example":true},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h3',{staticClass:"card-title"},[_vm._t("title",[_vm._v("Create New Location")])],2)]},proxy:true},{key:"toolbar",fn:function(){return [_c('div',[_c('router-link',{attrs:{"to":"/locations"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-primary",attrs:{"href":href,"title":"View All Types"},on:{"click":navigate}},[_c('i',{staticClass:"fas fa-list pr-0"})])]}}])})],1)]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"row"},[_c('form',{staticClass:"form col-md-8 mx-auto mt-5",attrs:{"novalidate":"novalidate","autocomplete":"false"},on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('div',{staticClass:"pb-5"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Location Name")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.name.$model),expression:"$v.name.$model",modifiers:{"trim":true}}],staticClass:"form-control form-control-solid form-control-lg",attrs:{"type":"text","placeholder":"Location Name","autocomplete":"off"},domProps:{"value":(_vm.$v.name.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.name, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.$v.name.$error)?[(!_vm.$v.name.required)?_c('span',{staticClass:"form-text text-danger"},[_vm._v(" Location name is required. ")]):_vm._e()]:_vm._e(),_c('span',{staticClass:"form-text text-muted"},[_vm._v("Please enter location name.")])],2),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Parent Location")]),_c('multiselect',{attrs:{"options":_vm.locations,"track-by":"name","label":"name","placeholder":"Select A Parent Location"},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [_vm._v(_vm._s(option.name))]}}]),model:{value:(_vm.parent),callback:function ($$v) {_vm.parent=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"parent"}}),_c('span',{staticClass:"form-text text-muted"},[_vm._v("Please select parent location if this location belongs to an existing location on pilot.")])],1)]),_c('div',{staticClass:"d-flex justify-content-end border-top pt-10 pb-10"},[_c('div',[_c('router-link',{attrs:{"to":"/locations"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('button',{staticClass:"btn btn-light-danger font-weight-bolder font-size-h5 px-15 py-4 my-3 mr-3",attrs:{"type":"button"},on:{"click":navigate}},[_vm._v(" Cancel ")])]}}])}),_c('button',{staticClass:"btn btn-primary font-weight-bolder font-size-h5 px-15 py-4 my-3 mr-3",attrs:{"type":"submit"}},[(_vm.loading == true)?_c('b-spinner',{attrs:{"variant":"light","label":"Spinning"}}):_vm._e(),(_vm.loading == false)?_c('span',[_vm._v("Create Location")]):_vm._e()],1)],1)])])])]},proxy:true}],null,true)})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }