<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <PilotCard ref="preview" v-bind:title="title" v-bind:example="true">
          <template v-slot:title>
            <h3 class="card-title">
              <slot name="title">Create New Location</slot>
            </h3>
          </template>
          <template v-slot:toolbar>
            <div>
              <router-link to="/locations" v-slot="{ href, navigate }">
                <a
                  :href="href"
                  class="btn btn-primary"
                  v-b-tooltip.hover
                  title="View All Types"
                  @click="navigate"
                >
                  <i class="fas fa-list pr-0"></i>
                </a>
              </router-link>
            </div>
          </template>
          <template v-slot:body>
            <div class="row">
              <form
                @submit.prevent="submit"
                class="form col-md-8 mx-auto mt-5"
                novalidate="novalidate"
                autocomplete="false"
              >
                <div class="pb-5">
                  <div class="form-group">
                    <label>Location Name</label>
                    <input
                      class="form-control form-control-solid form-control-lg"
                      type="text"
                      placeholder="Location Name"
                      autocomplete="off"
                      v-model.trim="$v.name.$model"
                    />
                    <template v-if="$v.name.$error">
                      <span
                        class="form-text text-danger"
                        v-if="!$v.name.required"
                      >
                        Location name is required.
                      </span>
                    </template>
                    <span class="form-text text-muted"
                      >Please enter location name.</span
                    >
                  </div>
                  <div class="form-group">
                    <label>Parent Location</label>
                    <multiselect
                      v-model.trim="parent"
                      :options="locations"
                      track-by="name"
                      label="name"
                      placeholder="Select A Parent Location"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">{{
                        option.name
                      }}</template>
                    </multiselect>
                    <span class="form-text text-muted"
                      >Please select parent location if this location belongs to
                      an existing location on pilot.</span
                    >
                  </div>
                </div>

                <div class="d-flex justify-content-end border-top pt-10 pb-10">
                  <div>
                    <router-link to="/locations" v-slot="{ navigate }">
                      <button
                        type="button"
                        class="btn btn-light-danger font-weight-bolder font-size-h5 px-15 py-4 my-3 mr-3"
                        @click="navigate"
                      >
                        Cancel
                      </button>
                    </router-link>
                    <button
                      type="submit"
                      class="btn btn-primary font-weight-bolder font-size-h5 px-15 py-4 my-3 mr-3"
                    >
                      <b-spinner
                        v-if="loading == true"
                        variant="light"
                        label="Spinning"
                      ></b-spinner>
                      <span v-if="loading == false">Create Location</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </template>
        </PilotCard>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import LocationService from "@/core/services/location.service.js";
import PilotCard from "@/view/content/Card.vue";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
export default {
  props: {
    title: String
  },
  data() {
    return {
      name: "",
      show: true,
      parent: null,
      locations: [],
      loading: false
    };
  },
  validations: {
    name: { required }
  },
  components: {
    PilotCard,
    Multiselect
  },
  methods: {
    async submit() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.loading = true;
        const payload = {
          name: this.name
        };

        if (this.parent !== null) {
          Object.assign(payload, { location_id: this.parent.id });
        }
        LocationService.create(payload)
          .then(response => {
            Swal.fire({
              title: "Location Saved",
              text: response.data.message,
              icon: "success",
              heightAuto: false
            });

            this.name = "";
            this.parent = null;
            this.loading = false;

            this.$nextTick(() => {
              this.$v.$reset();
            });
          })
          .catch(error => {
            Swal.fire({
              title: "Location Error",
              text: error.data.message,
              icon: "error",
              heightAuto: false
            });
            this.loading = false;
          });

        LocationService.all().then(response => {
          this.locations = response.data;
        });
      }
    }
  },
  computed: {
    /**
     * Check if has title
     * @returns {boolean}
     */
    hasTitleSlot() {
      return !!this.$slots["title"];
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Locations", route: "locations" },
      { title: "Create Locations" }
    ]);

    LocationService.all().then(response => {
      this.locations = response.data;
    });
  }
};
</script>
